import controlTypeFormStore from '@/modules/control-type/store/control-type-form-store';
import controlTypeListStore from '@/modules/control-type/store/control-type-list-store';

export default {
    namespaced: true,

    modules: {
        form: controlTypeFormStore,
        list: controlTypeListStore,
    },
};
